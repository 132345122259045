<template>
    <div class="customer">
      <div ref="contentTop">
        <div class="tool">
          <div class="customer-tool">
              <div></div>
              
              <div style="line-height: 40px">
                <el-button type="primary" class="hq-button" @click="saveModel = true">新增</el-button>
              </div>
          </div>
        </div>
        <div
          :gutter="10"
          style="width: 94%; margin: 0 auto"
        >
            <Search
            :searchAllParam="searchAllParam"
            @search="search"
            @reset="reset"
            :searchParamList="searchParamList"
            code="count"
            />
        </div>
        <div class="line"></div>
      </div>
      <el-row style="width: 94%; margin: 0 auto">
        <el-col>
          <el-table
            highlight-current-row
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            border
            stripe
            size="mini"
            v-loading="listLoading"
            element-loading-text="拼命加载中"
            :row-style="{ height: '20px' }"
            :cell-style="{ padding: '0px' }"
            :header-cell-style="{ background: '#e5f2ff' }"
            @row-click="clickRow"
          >
            <el-table-column :show-overflow-tooltip="true"
                label="提审人"
                prop="createName"
                min-width="100"
            ></el-table-column>
            <el-table-column :show-overflow-tooltip="true"
                label="提审时间"
                prop="createTime"
                min-width="100"
            ></el-table-column>
            <el-table-column :show-overflow-tooltip="true"
                label="变更理由"
                prop="changeReason"
                min-width="100"
            ></el-table-column>
            <el-table-column :show-overflow-tooltip="true"
                label="审核状态"
                prop="processStatename"
                min-width="100"
            ></el-table-column>
            <el-table-column :show-overflow-tooltip="true"
                label="钉钉编号"
                prop="processInstanceCode"
                min-width="100"
            ></el-table-column>
            <el-table-column
            label="操作"
            min-width="100"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button type="text"
                @click="changeJump(scope.row)"
                >提审文件下载</el-button>
            </template>
          </el-table-column>
          </el-table>
          <div class="page_test_bottom">
            <el-pagination
              background
              @size-change="sizeChange"
              @current-change="currentChange"
              layout="total, sizes, prev, pager, next, jumper"
              :current-page="pageParam.pageNum"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="pageParam.pageSize"
              :total="total"
            />
          </div>
        </el-col>
      </el-row>
      <HqModal :visible="saveModel" :title="'学历成本修改'" width="400" @on-cancel="cancel">
        <el-form ref="form" :model="form" :rules="formRules">
            <el-row :gutter="10">
                <el-col :span="24">
                    <span style="margin-right:10px">模板</span>
                    <a :href='`${publicPath}static/resource/temp.xlsx`'>请点击下载模板</a>
                </el-col>
                <el-col :span="24">
                    <el-upload 
                      style="margin-top: 20px"
                      :limit="1"
                      action="" 
                      :http-request="upload" 
                      :before-upload="beforeUploadFile"
                      :on-exceed="handleExceed">
                      <el-button type="primary">上传修改成本文件 <i class="el-icon-upload2"></i></el-button>
                    </el-upload>
                </el-col>
                <div class="lineclass"></div>
                <el-col :span="24">
                    <el-form-item prop="changeReason">
                        <v-textarea
                            v-model="form.changeReason"
                            outlined
                            :height="80"
                            placeholder="输入变更理由，200字内"
                            name="input-7-4"
                            label="变更理由"
                        ></v-textarea>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div slot="footer">
            <el-button @click="cancel">取消</el-button>
            <el-button @click="save" type="primary">确认</el-button>
        </div>
      </HqModal>
    </div>
</template>

<script>
import Search from "@/components/search";
import { mapGetters } from "vuex";
import common from '@/views/order/after-sale-nc/mixins/common'


export default {
    name: 'costmanagement',
    mixins: [common],
    components: {
        Search
    },
    data() {
        const searchParamList = ["createTime", "keyword", "status"]
        const validateReason = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入变更理由'));
            } else {
                if (value.length > 200) {
                    callback(new Error('变更理由输入长度不能大于200'));
                }
                callback();
            }
        };
        return {
            form: {
                changeReason: "",
            },
            formRules: {
                changeReason: [
                    {required: true, validator: validateReason}
                ],
            },
            saveModel: false,
            searchAllParam: [
                { 
                  name: "createTime", 
                  label: "提审时间", 
                  type: "date", 
                  value: [], 
                  ref: "xxxxx", 
                  show: false 
                },
                {
                    name: "processState",
                    label: "审核状态",
                    type: "select",
                    value: "",
                    option: [{id: 1, name: '审核中'}, {id: 2, name: '审核通过' }, {id: 3, name: '审核驳回'}],
                    text: "name",
                    id: "id",
                },  
                {
                    name: "processInstanceCode",
                    label: "钉钉编号",
                    type: "input",
                    value: "",
                },
            ],
            searchParamList: searchParamList,
            listLoading: false,
            tableData: [],
            pageParam: {
                pageNum: 1,
                pageSize: 10
            },
            total: 0,
            publicPath: process.env.BASE_URL,
            fileList: [],   // excel文件列表
        }
    },
    methods: {
        // 上传修改成本文件
        async upload(param) {
          const formData = new FormData()
          console.log('param', param);
          formData.append('upfile', param.file)
          const res = await this.$fetch("third_uploadPic", formData)
          console.log('res文件上传', res);
          if(res.state == "SUCCESS") {
            this.form.changeFile = res.url
          }
        },
        beforeUploadFile(file) {
          console.log('file',file);
          let extension = file.name.substring(file.name.lastIndexOf('.') + 1)
          let size = file.size / 1024 / 1024
          if (extension !== 'xlsx') {
            this.$message.warning('只能上传后缀是.xlsx的文件')
          }
          if (size > 10) {
            this.$message.warning('文件大小不得超过10M')
          }
        },
        handleExceed(files, fileList) {
          this.$message.error('请删除当前上传的文件！')
        },
        // 更新自定义查询
        searchList() {
            this.getSearchList();
        },
        async getSearchList() {
            this.searchParamList = this.searchAllParam;
        },
        // 获取列表数据
        async GetList() {
          console.log('this.searchParam', this.searchParam);
            let params = {
                curPage: this.pageParam.pageNum,
                pageSize: this.pageParam.pageSize,
                ...this.searchParam
            }
            console.log('params', params);
            const res = await this.$fetch("third_orderCostUpdate", params)
            this.listLoading = true
            console.log('res', res);
            if(res.code == 200) {
              this.tableData = res.data || []
              this.tableData.map((item) => {
                if (item.processState == 1) {
                  item.processStatename = '审核中'
                } else if (item.processState == 2) {
                  item.processStatename = '审核通过'
                }else if (item.processState == 3) {
                  item.processStatename = '审核驳回'
                }
              })
              this.total = res.pageInfo.total
            } else {
              this.$message.error(res.msg)
            }
            this.listLoading = false
        },
        search() {
          this.searchParam = {}
          this.searchAllParam.forEach( item => {
          if( (item.value !== undefined && item.value !== null && item.value !== '' ) || ( Array.isArray(item.value)  && item.value.length ) ) {
                    if(item.type === "date") {
                      if(item.name === "createTime") {
                        console.log('item.value[0]', item.value[0]);
                        this.searchParam.createTimeBegin = item.value[0];
                        this.searchParam.createTimeEnd = item.value[1];
                      }
                    } else {
                      this.searchParam[item.name] = item.value 
                    }
              }
            })

            this.GetLissearch()
        },
        // 提取文件
        changeJump(id) {
          console.log(id);
          if (id.changeFile) {
            window.open(id.changeFile)
          } else {
            this.$message.error('无资源下载')
          }
        },
        reset() {
          this.searchParamList.map(item => {
            if(item.value || item.value == 0) {
              if(item.name === "createTime") {
                item.value = []
              } else {
                item.value = ""
              }
            }
          })
          this.pageParam = {
            pageNum: 1,
            pageSize: 10,
          }
          this.total = 0
          this.searchParam.processState = ''
          this.searchParam.processInstanceCode = ''
          this.searchParam.createTimeBegin = '';
          this.searchParam.createTimeEnd = '';
          this.GetLissearch()
        },
        // 获取列表数据
        async GetLissearch() {
          if (this.searchParam.createTimeBegin == '' || this.searchParam.createTimeBegin == undefined) {
            this.searchParam.createTimeBegin = ''
          } else {
            this.searchParam.createTimeBegin = `${this.searchParam.createTimeBegin} 00:00:00`
          }
          if (this.searchParam.createTimeEnd == '' || this.searchParam.createTimeEnd == undefined) {
            this.searchParam.createTimeEnd = ''
          } else {
            this.searchParam.createTimeEnd = `${this.searchParam.createTimeEnd} 23:59:59`
          }
          console.log('this.searchParam', this.searchParam);
            let params = {
                curPage: this.pageParam.pageNum,
                pageSize: this.pageParam.pageSize,
                ...this.searchParam
            }
            console.log('params', params);
            const res = await this.$fetch("third_orderCostUpdate", params)
            this.listLoading = true
            console.log('res', res);
            if(res.code == 200) {
              this.tableData = res.data || []
              this.tableData.map((item) => {
                if (item.processState == 1) {
                  item.processStatename = '审核中'
                } else if (item.processState == 2) {
                  item.processStatename = '审核通过'
                }else if (item.processState == 3) {
                  item.processStatename = '审核驳回'
                }
              })
              this.total = res.pageInfo.total
            } else {
              this.$message.error(res.msg)
            }
            this.listLoading = false
        },
        clickRow() {

        },
        sizeChange(val) {
          this.pageParam.pageSize = val;
          this.GetList()
        },
        currentChange(val) {
          this.pageParam.pageNum = val;
          this.GetList()
        },
        cancel() {
            this.saveModel = false
            this.form.changeReason = ""
            this.form.changeFile = ""
        },
        async save() {
          this.form.createBy = this.loginUser.tyUserId ? this.loginUser.tyUserId : ''
          console.log('this.getTyUser().id', this.getTyUser(), '---',this.loginUser);
          if(this.form.createBy == '') {
            return this.$message.error('请绑定天翼用户id再进行操作')
          }
          let params = {
            createBy: this.form.createBy,
            changeReason: this.form.changeReason,
            changeFile: this.form.changeFile ? this.form.changeFile : ''
          }
          console.log('params', params);
          const res = await this.$fetch("third_orderCostUpdatesave", params).catch(err => {
						err.msg && this.$message.warning(err.msg)
					})
          console.log('res,提交', res);
          if (res.code && res.code == 200) {
            this.$message.success(res.msg)
            this.cancel()
            this.GetList()
          } else {
            this.$message.error(res.msg)
          }
        }
    },
    computed: {
      ...mapGetters([
        "getTyUserInfo", "loginUser"
      ]),
    tableHeight() {
      return window.outerHeight - this.topHeight - 256 + 'px'
    }
  },
    mounted() {
        this.searchList()
        this.GetList()
    }
}
</script>

<style lang="scss" scoped>
.lineclass {
    margin-top:20px;
    height: 10px;
    width: 100%;
    display: inline-block;
}
.customer {
  // padding-bottom: 40px;
  .titie {
    text-align: left;
  }

  .tool {
    background: #f5f7f9;
  }

  .customer-tool {
    display: flex;
    justify-content: space-between;
    width: 94%;
    margin: 0 auto;

    .tab-bar {
      overflow: hidden;

      .active {
        color: #409eff;
        position: relative;

        &::after {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 4px;
          background-color: #337aff;
          bottom: 0;
          left: 0;
        }
      }

      > span {
        float: left;
        margin-right: 12px;
        line-height: 40px;
        text-align: center;
        text-decoration: none;
        color: #000;
        cursor: pointer;
        font-size: 14px;
        padding: 0 10px;
      }
    }
  }
  .phone {
    .el-button--text {
      padding: 10px 4px;
    }
  }
}
::v-deep .action_col{
  padding: 4px 0 !important;
}
.action_btn{
  width: 60px;
  padding: 0;
  height: 24px;
  line-height: 24px;
  background-color: #337AFF;
  font-size: 13px;
  &.is-disabled{
    background-color: #E1E4EB;
    border-color: #E1E4EB;
  }
}
</style>
